export function calculateRemainingVePHUX(newVePHUX, lockedVePHUX, totalVePHUXStaked, newShare, share, totalShare, totalStakedLiquidity) {

    let remainingVePHUX = 0;
    const liquidity_provided = Number(newShare) + Number(share);
    // Calculate minvePHUX for Max Boost
    let minvePHUX = (totalVePHUXStaked) * (liquidity_provided / ( Number(totalStakedLiquidity) + Number(newShare)));
    if (minvePHUX > 50000000) {
        minvePHUX = 0;
    }

    remainingVePHUX = minvePHUX - newVePHUX - lockedVePHUX;

    if (remainingVePHUX < 0) {
         remainingVePHUX = 0;
    }

    return remainingVePHUX;
}