import React  from 'react';
import Link from "@mui/material/Link";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FavoriteIcon from '@mui/icons-material/Favorite';


export default function Footer() {
    return (
        <footer style={{overflow: "hidden", textOverflow: "ellipsis", position: "relative", bottom: 0}}>
                <Grid >
                    <Typography noWrap={false} variant="caption" color="textSecondary" component="span" display="block">
                        {"Made by Xeonus and Zen Dragon, modified for PHUX by "} 
                        <Link color="inherit" href="https://korkey.tech/">@korkey128k</Link>
                        <FavoriteIcon fontSize="small"></FavoriteIcon>  {"for the "}
                        <Link color="inherit" href="https://phux.io/">
                            PHUX
                        </Link>{" "}
                        {"Community"}
                    </Typography>
                </Grid>

                <Grid>
                    <Typography noWrap={false} variant="caption" color="textSecondary" component="span" display="block">
                        Donate: {" "}
                        <Link color="inherit" href="https://scan.pulsechain.com/address/0xCF5FF62E441F052ad1CFeD4Ac5b4E3c83ff1F501" target="_blank">
                            0xcf5ff62e441f052ad1cfed4ac5b4e3c83ff1f501
                        </Link> <br />
                        Information provided as is. Not financial advice. <br />
                        Beta Build - v0.0.1 - <Link color="inherit" href="https://gitlab.com/korkey.dev/vephux-tools" target="_blank">
                            (Source Code)
                        </Link>
                    </Typography>
                </Grid>
        </footer>
    );
}