import gql from 'graphql-tag';
export const getBalancerPoolData = gql`
{
  balancers(first: 1000) {
    id
    pools(first: 1000) {
      name
      totalLiquidity
      totalShares
      poolType
      swapFee
      tokens {
        symbol
        id
        address
        balance
        weight
      }
      id
    }
    totalLiquidity
  }
}
  `;

export const getGaugeData = gql`
{
  votingEscrows {
    stakedSupply
  }
  liquidityGauges {
    symbol
    poolAddress
    poolId
    totalSupply
    id
    shares {
      balance
    }
  }
}
  `;

export const getGaugeUserVotes = gql`
{
  gaugeVotes(
    where: {gauge_contains_nocase: "0xF0ea3559Cf098455921d74173dA83fF2f6979495", timestamp_lt: 1661979600}
  ) {
    weight
    user {
      votingLocks {
        lockedBalance
      }
      id
    }
  }
}
  `;

export const getVePHUXPoolData = gql`
{
  balancers(first: 500) {
    id
    pools(first: 500, where: {id: "0x545998abcbf0633c83ba20cb94f384925be75dd5000200000000000000000000"}) {
      name
      totalLiquidity
      totalShares
      poolType
      swapFee
      tokens {
        symbol
        id
        address
        balance
        weight
      }
      id
    }
    totalLiquidity
  }
}
  `;
