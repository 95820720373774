//Helper function to calculate minimum vePHUX for Max Boost for a certain gauge / vePHUX configuration
export function calculateMinVePHUX(newVePHUX, lockedVePHUX, totalVePHUXStaked, newShare, share, totalShare, totalStakedLiquidity) {

    //Calculate working supply:
    const liquidity_provided = Number(newShare) + Number(share);
    // Calculate minvePHUX for Max Boost
    let minvePHUX = (totalVePHUXStaked) * (liquidity_provided / ( Number(totalStakedLiquidity) + Number(newShare)));
    if (minvePHUX > 50000000) {
        minvePHUX = 0;
    }
    return minvePHUX;

}