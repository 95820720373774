// Source: 
// https://sub5.phatty.io/subgraphs/name/phux/gauges/graphql?query=%7B%0A++gauges%28first%3A+1000%29+%7B%0A++++liquidityGauge+%7B%0A++++++poolId%0A++++%7D%0A++%7D%0A%7D
export const GAUGE_WHITELIST_MAINNET = [
  "0x05ac1846d32728c28b798212f3da687052002f5b0002000000000000000001f2",
  "0xdd0076e21c29e4efab9f03dbceaafca912d2165f0002000000000000000002bf",
  "0x9009c1de3220caf855f83140e5ac18a43272ec01000000000000000000000187",
  "0xdca649ed66b8fb2f1b723b6e7f85f477c44da84200010000000000000000024d",
  "0x287157a2d9430cf30526cfb88df4aa23310f9f55000100000000000000000108",
  "0x47e6ec3ad75c31e8b6400135a11d544ed205841000010000000000000000024a",
  "0xfa41c8cb4d02d1ab4536c27a664cbb8b3c0937a00000000000000000000002dd",
  "0xb94fda7e9c1b32a89afee3652fe83cf7b3903d8b0001000000000000000002ae",
  "0xe00a24da1720086ac87cdd89230497047c64f61a00020000000000000000001c",
  "0x76a99b7799aecd15f4d2061e7491fcd0cd4d31880001000000000000000002bd",
  "0xce3181167c03ab5a6d84c1a93716521e83a6300b000100000000000000000002",
  "0xb970e810b18aeee66ce0bcf2526eb0115f6c2cb30001000000000000000001d8",
  "0x7962f72cb8d2a1924cb49e718be2c370cf578432000100000000000000000005",
  "0xf5bbe8f8b6048abcb4c733e7360534b8b7336e3a0001000000000000000001e3",
  "0xd444b7a1d07727a042b61b83ff3d99e93048f2f4000100000000000000000007",
  "0xcddaede234bf06082198b38567c05c40df5b6b3100010000000000000000009c",
  "0x0d42e15dcb195ad1b27a76cf3f9352160474d09900010000000000000000003b",
  "0x1bb0b07ee7e95f2b8bbf115385311dc63638abcf000200000000000000000192",
  "0xa11965f3f25d85ed107e02c166c664ffd292d2d5000200000000000000000244",
  "0x146779adbbf14e4e23727a2ddc03130f6d0c0cf000010000000000000000029b",
  "0x6987772330a72637dee68ae3dd4b55cf643190bd000200000000000000000096",
  "0x4942ab2e69ded72627eec632bc30e246e5d0ef88000100000000000000000191",
  "0x0d705aef99d26489c2df742653f5f600a44752080001000000000000000001d7",
  "0xf96d60e9444f19fe5126888bd53bde80e58c2851000000000000000000000001",
  "0x014f2620a91ad4541b7dd9a1191acb0909d714940002000000000000000001d6",
  "0xca994c176c00fcb3d2ffc3d4df41885fb978ce420001000000000000000000b7",
  "0xad4550155f7d62f1378b12c36f2e4fb3af61852100010000000000000000000c",
  "0x7e07bcc72f2d3201cc3fdc6d92f944a24f12b952000100000000000000000069",
  "0xd1aad7acf9b364f0d1e9b5fd248a6a2a3cfba1020001000000000000000000ec",
  "0xd1b9544c5be500a002e6a5c8ce4f1f53d16aa108000200000000000000000023",
  "0x74776e3ef290034ab183585da17d0bc90bfcbb1d0001000000000000000000a0",
  "0x885e51d75d7420d8ddb5ec6504d1a5a00b5ac37b0001000000000000000000e8",
  "0x7801654b9367ef5d8a460de8b675e1cb3d65daea0001000000000000000000e5",
  "0xfeb8db44f5e3b107d7feaa9af2bf689aae246bf3000200000000000000000008",
  "0x152a772d866e7ccf57f9b37c8195b086df060962000100000000000000000009",
  "0xcbb3087db647cc8e6eaa454174a6e86f8c421378000200000000000000000149",
  "0x5f473a70e89778d5c600f2c0e692a86c26792a250001000000000000000000bd",
  "0xa0f68e330db58895c2f2b5bb74e2b664baae707d000100000000000000000168",
  "0x82cbf8c6bee990203015fe33a228a7b2aedb27e200020000000000000000024c",
  "0x6c7ed1c6255d6d1d927813fdea22834f9cc2c2ba000200000000000000000269",
  "0x0c2d61b0f8dfc00172f9751497fc08ecf203d03f00010000000000000000028f",
  "0xc116c38b1ef97fe0adb32c96672ac85079d4e3c0000100000000000000000006",
  "0xc26e38fc7a803a11eadd2468e6884be41952746d0001000000000000000000f5",
  "0xb584a3754c3219187bb05474579dfa11cbb960c40002000000000000000000d9",
  "0x0b41674002756d967bf99f6c7d98bb4f7e3c929400020000000000000000005c",
  "0x73e2218d8cf33b0bfcbf124c09b00e973a2bbae300010000000000000000000d"
];