import React from "react";
import {Helmet} from "react-helmet";
 
export default class StatsLog extends React.Component {
  render () {
    return (
        <div className="application">
            <Helmet>
                <script data-goatcounter="https://stats.korkey.tech/count" async="true" src="https://stats.korkey.tech/count.js"></script>
            </Helmet>
        </div>
    );
  }
};